import Vue from 'vue'
import App from './App.vue'


//import * as Vue from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false


//axios.defaults.headers.common['APIKEY'] = process.env.VUE_APP_APIKEY;
axios.defaults.headers = {
  'APIKEY': process.env.VUE_APP_APIKEY,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
};
Vue.use(VueAxios, axios)



import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
library.add(faSpinner)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  render: h => h(App),
}).$mount('#app')

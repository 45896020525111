<template>
  <div>
    <b-container class="text-left">
      <b-form-row
        ><b-col
          ><b-form-group description="Ingrese el número de su orden" class="mb-2">
            <b-input-group>
              <b-input
                v-model="hapid"
                size="sm"
                placeholder="Ej. 32512-02"
                :state="state"
              ></b-input>
              <b-input-group-append>
                <b-button @click="buscar" size="sm" :disabled="!state" variant="primary">Buscar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row
        ><b-col>
          <div v-if="waiting">
            <h3 class="text-center">Por favor espere unos segundos ...</h3>
            <h3 class="text-center">
              <font-awesome-icon icon="spinner" class="fa-spin" />
            </h3>
          </div>
          <div v-if="response">
            <div v-if="response.error" class="text-danger">{{ response.error }}</div>
            <div v-else>
              <small>
                <b-table
                  id="tabla"
                  ref="tabla"
                  :items="data4show"
                  :fields="fields"
                  small
                  head-variant="dark"
                  stacked
                ></b-table
              ></small>
            </div>
          </div>
        </b-col>
      </b-form-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "WinkommerceGet",
  data() {
    return {
      apiurl: process.env.VUE_APP_API2,
      hapid: "",
      response: null,
      waiting: false,
      fields: [
        { key: "hapId", label: "Presupuesto" },
        { key: "obra", label: "Obra" },
        { key: "direccion", label: "Direccion" },
        { key: "comuna", label: "Comuna" },
        { key: "fech", label: "Fecha" },
        { key: "fchFinalPlanta", label: "Fecha Final Planta" },
        { key: "fchDespacho", label: "Fecha de Despacho" },
        { key: "obs", label: "Observaciones" },
      ],
    };
  },
  computed: {
    data4show: function () {
      if (this.response && this.response.data) {
        return [this.response.data];
      } else {
        return [];
      }
    },
    state: function () {
      return this.hapid.trim().length > 2 ;
    },
  },
  methods: {
    buscar: function () {
      let url = this.apiurl + this.hapid;
      //console.log("buscando " + this.hapid);
      //console.log("url " + url);
      var self = this;
      self.response = null;
      self.waiting = true;
      axios
        .get(url, {
          responseType: "json",
        })
        .then(function (response) {
          //console.log(response.data);
          self.waiting = false;
          self.response = response.data;
        })
        .finally(() => {
          self.waiting = false;
        });
    },
  },
};
</script>

